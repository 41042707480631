import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from '../components/Section';

import NotYetSection from '../sections/not-yet';
import DownloadSection from '../sections/download';

var featureList = [
  {
    icon: '01-sefaz.png',
    title: 'SEFAZ Estaduais',
    description: 'Tenha informações, atalhos e utilidades separadas pelo estado de interesse. Siga os estados e seja notificado quando houver novidades.'
  },
  {
    icon: '02-consultas.png',
    title: 'Relacionadas',
    description: 'Uma lista completa com consultas relacionadas com tributação, reunidas para você em um só lugar.'
  },
  {
    icon: '03-calculadoras-icms.png',
    title: 'Calculadoras de ICMS',
    description: 'Chega de complicação na hora de calcular o ICMS de suas mercadorias. cálculo simplificado, rápido e intuitivo.'
  },
  {
    icon: '04-forum.png',
    title: 'Fórum de discussões',
    description: 'Área livre para os usuários discutirem sobre diversos assuntos relaciondados com tributação e negócios.'
  },
  {
    icon: '05-consultoria.png',
    title: 'Consultoria Online',
    description: 'Tem um problema ou dúvida bem específico? Você pode falar diretamente com um de nossos especialistas e acabar com as suas dúvidas.'
  },
  {
    icon: '06-dicas-icms.png',
    title: 'Dicas tributárias',
    description: 'Conte com disversas dicas de especialistas para não correr perigo de ser multado.'
  },
  {
    icon: '07-fluxo-caixa.png',
    title: 'Fluxo de Caixa',
    description: 'Controle seus ativos e passivos e tenha um histórico e previsões do seu caixa.'
  },
  {
    icon: '08-indicacoes-apps.png',
    title: 'Indicação de Apps',
    description: 'Receba indicações de aplicativos que podem ser utéis no seu dia-a-dia.'
  },
  {
    icon: '09-receita-federal.png',
    title: 'Receita Federal',
    description: 'Conte com disversas dicas de especialistas para não correr perigo de ser multado.'
  }
];

const MainSection = () => (
  <div className="container">
    <h2>Confira alguns de nossos principais recursos</h2>
    Aqui está uma pequena lista de coisas que irão otimizar seu tempo.

    <br/>
    <br/>
    <br/>

    <div className="container">
      <div className="row">
        {
          featureList.map((feature) => (
            <div className="col-lg-4 col-sm-12">
              <div className="app-feature">
                <div className="app-feature-icon">
                  <img src={`/public/images/features/${feature.icon}`} alt={`Imagem ilustrativa de "${feature.title}"`} height="80" />
                </div>
                <div className="app-feature-content">
                  <h3>{feature.title}</h3>
                  <span>
                    <p>{feature.description}</p>
                  </span>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>

    <br/>
    <br/>
    <p>E não paramos por aqui, estamos sempre atualizando nossa ferramenta para trazer cada vez mais novidades para vocês.</p>
    <p><a href="/blog">Clique aqui</a> e veja o que vem por aí.</p>
  </div>
)

const IndexPage = () => (
  <Layout open>
    <SEO title="Home" />

    <Section>
      <MainSection />
    </Section>

    <Section reverse divider>
      <NotYetSection />
    </Section>

    <Section divider>
      <DownloadSection />
    </Section>
  </Layout>
)

export default IndexPage
