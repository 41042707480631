import React from "react"

const NotYetSection = () => (
  <div className="container" id="not-yet">
    <h1>Ainda não está convencido?</h1>
    <p style={{fontSize: '130%'}}>
      Bom... Eu já te falei você pode <strong>usar de graça</strong>? isso mesmo!
    </p>

    <br/>

    <p>
      Você não precisa pagar nada para utilizar nosso app apenas se você quiser ter acesso a nossos recursos premium.
    </p>

    <br/>

    <div style={{marginLeft: '45px'}}>
      <a href="/precos" className="btn btn-info">
        <i className="fa fa-star"></i>
        Ver premium
      </a>
      Confira aqui nossos recursos premium.

      <br/>
      <br/>

      <a href="/home#register" className="btn btn-success">
        <i className="fa fa-user"></i>
        Cadastre-se
        <small>Grátis</small>
      </a>
      Cadastre-se agora e tenha 15 dias premium grátis.
    </div>
  </div>
)

export default NotYetSection;