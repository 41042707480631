import React from "react"
import config from '../config'

const DownloadSection = () => (
  <div className="container" id="download">
    <h1>Download / Acesso</h1>
    <p>
      Acessa acessar no App basta escolher uma das opções disponíveis abaixo.
    </p>

    <br/>
    
    <div className="download-buttons">
      <section>
        <h4 style={{color: '#828dad'}}>Versão web</h4>
        <a href={config.endpoints.app} className="btn btn-success download-button">
          <i className="fa fa-globe download-button-icon" style={{fontSize: '28px', color: '#000'}}></i>
          <div>
            Dashboard<br/>
            <small>para Computador</small>
          </div>
        </a>
      </section>

      <div style={{float: 'left'}}>
        <span style={{padding: '15px'}}></span>
      </div>

      <section>
        <h4 style={{color: '#828dad'}}>Versão mobile</h4>
        <div>
          <div style={{float: 'left'}}>
            <a href={config.download.android} className="btn btn-google-play download-button">
              <img className="download-button-icon" src="./public/images/buttons/google-play.svg" 
                alt="Baixar no Google Play" height="28px" />
              <div>
                Google Play<br/>
                <small>para Android</small>
              </div>
            </a>
          </div>
          <div style={{float: 'left'}}>
            <span style={{padding: '7px'}}></span>
          </div>
          <div style={{float: 'left'}}>
            <a href={config.download.ios} className="btn btn-apple-store download-button">
              <img className="download-button-icon" src="./public/images/buttons/app-store.svg"
                alt="Baixar na AppStore" height="28px" />
              <div>
                AppleStore <br/>
                <small>para IOS</small>
              </div>
            </a>
          </div>
        </div>
      </section>
    </div>
    <br/>
  </div>
)
export default DownloadSection;